import React from "react";
import NoDataFound from "../Common/NoDataFound";
import { Alert } from "bootstrap";
import Badge from "react-bootstrap/Badge";
import {
  convertFirstLetterOfFullNameToCapital,
  customName,
} from "../../helpers/commonHelpers";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Common/Loader";

const MeetingAttendance = () => {
  const meetingData = useSelector((state) => state.meeting);
  console.log(meetingData);

  return (
    <div className="form-group agenda">
      <label className="mt-2 mb-3 add-agenda">
        <h4>Meeting Attendance</h4>
      </label>
      <div className="mt-2 agenda-box-border attendees-popup-list">
        {/* <div className="form-group m-0">
          <div className="row">
            <div className="col-12">
              <label className="mt-1 p-3 topic-head"> Meeting Attendance</label>
            </div>
          </div>
        </div> */}

        {meetingData.meetingDetails?.attendees.length > 0 ? (
          <form className=" details-form details-form-right responsive-table tbl-2-responsive ">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Sl. No.</th>
                  <th scope="col">Attendees</th>
                  <th scope="col">Attendance</th>
                </tr>
              </thead>

              <tbody>
                {meetingData.meetingDetails?.attendees.length !== 0 ? (
                  <>
                    {meetingData.meetingDetails?.attendees.map(
                      (attendee, index) => {
                        return (
                          <tr key={index}>
                            <td data-label="Sl. No." className="blue-bold-text" scope="row">{index + 1}</td>
                            <td data-label="Attendees">
                              <div className="attendees responsive-right">
                                <div className="attendee-list">
                                  {customName(attendee.name)}
                                </div>
                                {/* <span className="ms-2">
                                  {convertFirstLetterOfFullNameToCapital(
                                    attendee.name
                                  )}
                                </span> */}
                                <div className="action-cmnt-text">
                                  <p className="detail-name">
                                    {convertFirstLetterOfFullNameToCapital(
                                      attendee.name
                                    )}
                                  </p>
                                  {/* body > ngb-modal-window > div > div > div.modal-body.attendees-popup > div:nth-child(1) > div.action-cmnt-text >  */}
                                  <p className="name-undertext comment-text">
                                    {attendee.email}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td data-label="ATTENDANCE">
                          
                              {meetingData?.meetingDetails?.isAttendanceAdded && attendee.isAttended ? (
                                <p>
                                  <Badge bg="success">Present</Badge>
                                </p>
                              ) : meetingData?.meetingDetails?.isAttendanceAdded && attendee.isAttended===false? (
                                <p>
                                  <Badge bg="danger">Absent</Badge>
                                </p>
                              ):
                              <p>
                              <Badge bg="dark">NA</Badge>
                            </p>
                              
                              }
                          
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </>
                ) : null}
              </tbody>
            </table>
          </form>
        ) : meetingData.meetingDetails?.attendees === 0 &&
          !meetingData.loading ? (
          <div className="no-data-img">
            <Alert
              status={"info"}
              message={"No data available."}
              timeoutSeconds={0}
            />
            <NoDataFound dataType={"meetingActivities"} />
          </div>
        ) : (
          <div
            className="meeting-page "
            style={{ textAlign: "center", paddingTop: 20 }}
          >
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default MeetingAttendance;

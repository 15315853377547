import React from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
const ActionDropDown = (props) => {
  console.log("props---------------status", props);
  const meetingData = useSelector((state) => state.meeting);
  console.log(meetingData?.meetingDetails)
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#000"
            className="bi bi-three-dots-vertical"
            viewBox="0 0 16 16"
          >
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
          </svg>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {props.isFollowOnMeeting ? (
            <>
              {props?.actionDetails?.isReopened === false && props?.actionDetails?.isApproved===false &&
              props?.actionDetails?.isComplete ? (
                <Dropdown.Item
                  href="#/action-2"
                  onClick={() => {
                    props.handleReopenActionModal();
                  }}
                >
                  <Link
                    // state={{ meetingId: props.meetingId,isViewMeetingPage:true}}
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="currentColor"
                      className="bi bi-arrow-clockwise me-2"
                      viewBox="0 0 19 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                      />
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                    </svg>
                    Reopen
                  </Link>
                </Dropdown.Item>
              ) : null}
              {props.isFollowOnMeeting &&
              props?.actionDetails?.isApproved === false &&
              props?.actionDetails?.isComplete ? (
                <Dropdown.Item
                  href="#/action-2"
                  onClick={() => {
                    props.handleApproveActionModal();
                  }}
                >
                  <Link
                    to=""
                    // state={{ meetingId: props.meetingId,isViewMeetingPage:true}}
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="currentColor"
                      className="bi bi-check me-2"
                      viewBox="0 0 19 16"
                    >
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                    </svg>
                    Approve
                  </Link>
                </Dropdown.Item>
              ) : null}

              <Dropdown.Item
                href="#/action-2"
                onClick={() => {
                  props.handleCancelActionModal();
                }}
              >
                <Link
                  to=""
                  // state={{ meetingId: props.meetingId,isViewMeetingPage:true}}
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    className="bi bi-x me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                  </svg>
                  Cancel
                </Link>
              </Dropdown.Item>
            </>
          ) : (
            <>
              <Dropdown.Item
                href="#/action-2"
                onClick={() => {
                  props.handleRequestForEditModal();
                }}
              >
                <Link
                  // state={{ meetingId: props.meetingId,isViewMeetingPage:true}}
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-pencil-square me-1"
                    viewBox="0 0 19 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                    />
                  </svg>
                  Edit
                </Link>
              </Dropdown.Item>
              {props.isShowDelete && meetingData?.meetingDetails?.momGenerationDetails?.length===0?(
              <Dropdown.Item
                href="#/action-2"
                onClick={() => {
                  props.handleRequestForDeleteModal();
                }}
              >
                <Link
                  // state={{ meetingId: props.meetingId,isViewMeetingPage:true}}
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi-minus-circle bi bi-dash-circle me-1"
                    viewBox="0 0 19 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
                  </svg>
                  Delete
                </Link>
              </Dropdown.Item>
              ):null}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default ActionDropDown;

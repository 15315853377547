import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate, Link, useParams } from "react-router-dom";

import {
  getSingleMeetingDetails,
  unSetSingleMeetingDetails,
} from "../../redux/actions/meetingActions/MeetingAction";
import Header from "../Common/Header/Header";
import MeetingHeader from "../Common/Header/MeetingHeader";
import Sidebar from "../Common/Sidebar/Sidebar";
import {
  customName,
  formatDateTimeFormat,
  getTimeSession,
} from "../../helpers/commonHelpers";
import AttendeesDetails from "./AttendeesDetails";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import { logOut } from "../../redux/actions/authActions/authAction";
import { ToastContainer, toast } from "react-toastify";

const ViewMeetingDetails = () => {
  let { id } = useParams();

  console.log(id);
  const location = useLocation();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser) {
    console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const stateData = location.state;
  console.log(stateData);
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));

  const meetingRoomData = useSelector((state) => state.meetingRoom);
  const meetingData = useSelector((state) => state.meeting);
  const employeeData = useSelector((state) => state.user);

  // if (employeeData?.userData === null) {
  //   localStorage.removeItem("accessToken");
  //   localStorage.removeItem("userData");
  //   localStorage.removeItem("rememberMe");
  //   navigate("/login");
  // }
  const [isViewMeetingPage, setIsViewMeetingPage] = useState(false);
  console.log(meetingData);
  console.log("use effect------------------------------------");
  useEffect(() => {
    document.title = "Meeting Details: MinutesVault";
    console.log("use effect------------------------------------");
    console.log(stateData);
    if (accessToken) {
      dispatch(getSingleMeetingDetails(id, accessToken));
    }
    // dispatch()

    return () => {
      console.log("return useeffect--------------->>>>>>>>>>>>>>");

      dispatch(unSetSingleMeetingDetails);
    };
  }, []);
  let isRoleAllowed = false;
  const isAttendee = meetingData?.singleMeetingDetails?.attendees?.find(
    (item) => item?._id?.toString() === employeeData?.userData?._id?.toString()
  );
  console.log("isAttendee----------------", isAttendee);
  // if (meetingData.singleMeetingDetails && employeeData?.userData) {
  //   if (employeeData?.userData?.isAdmin === true) {
  //     console.log("allowed1");
  //     isRoleAllowed = true;

  //     // alert("inside if")
  //   } else {
  //     // alert("inside else")
  //     console.log("blocked1");
  //     if (employeeData?.userData?.isMeetingOrganiser === true) {
  //       console.log("allowed2");
  //       isRoleAllowed = true;
  //     } else {
  //       console.log("blocked2");
  //       if (isAttendee) {
  //         console.log("allowed3");
  //         isRoleAllowed = true;
  //       } else {
  //         if (
  //           meetingData?.singleMeetingDetails?.createdById?.toString() ===
  //           employeeData?.userData?._id.toString()
  //         ) {
  //           console.log("allowed4");
  //           isRoleAllowed = true;
  //         } else {
  //           console.log("blocked3");
  //           isRoleAllowed = false;
  //         }
  //       }
  //     }

  //     if (isRoleAllowed === true) {
  //       console.log("final allowed");
  //     } else {
  //       console.log("final blocked");
  //       if (accessToken) {
  //         localStorage.removeItem("accessToken");
  //         localStorage.removeItem("userData");
  //         localStorage.removeItem("rememberMe");
  //         dispatch(logOut());
  //         navigate("/login");
  //         toast.error("Sorry. You do not have permission for this page.", {
  //           position: "top-right",
  //           autoClose: 3000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //           // transition: Bounce,
  //         });
  //       }
  //     }
  //     // if (
  //     //   (isAttendee && meetingData.singleMeetingDetails.createdById ===
  //     //     employeeData?.userData?._id.toString() &&
  //     //     ((employeeData?.userData?.isMeetingOrganiser === true &&
  //     //       employeeData?.userData?.isAdmin === false) ||
  //     //       (employeeData?.userData?.isMeetingOrganiser === false &&
  //     //         employeeData?.userData?.isAdmin === true) ||
  //     //       (employeeData?.userData?.isMeetingOrganiser === true &&
  //     //         employeeData?.userData?.isAdmin === true) ||
  //     //       meetingData?.meetingDetails?.canWriteMOM === true)) ||
  //     //   (isAttendee && meetingData.singleMeetingDetails.createdByIdd ===
  //     //     employeeData?.userData?._id.toString() &&
  //     //     ((employeeData?.userData?.isMeetingOrganiser === true &&
  //     //       employeeData?.userData?.isAdmin === false) ||
  //     //       (employeeData?.userData?.isMeetingOrganiser === false &&
  //     //         employeeData?.userData?.isAdmin === true) ||
  //     //       (employeeData?.userData?.isMeetingOrganiser === true &&
  //     //         employeeData?.userData?.isAdmin === true))) ||
  //     //   (isAttendee && meetingData.singleMeetingDetails.createdById ===
  //     //     employeeData?.userData?._id.toString() &&
  //     //     ((employeeData?.userData?.isMeetingOrganiser === true &&
  //     //       employeeData?.userData?.isAdmin === false) ||
  //     //       (employeeData?.userData?.isMeetingOrganiser === false &&
  //     //         employeeData?.userData?.isAdmin === true) ||
  //     //       (employeeData?.userData?.isMeetingOrganiser === true &&
  //     //         employeeData?.userData?.isAdmin === true)))
  //     // ) {
  //     //   console.log("is allowed");
  //     //   // alert("inside if")
  //     // } else {
  //     //   // alert("inside else")
  //     //   console.log("blocked");
  //     //   console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
  //     //   // if (accessToken) {
  //     //   //   localStorage.removeItem("accessToken");
  //     //   //   localStorage.removeItem("userData");
  //     //   //   localStorage.removeItem("rememberMe");
  //     //     // dispatch(logOut());
  //     //     // navigate("/login");
  //     //     toast.error("Sorry. You do not have permission for this page.", {
  //     //       position: "top-right",
  //     //       autoClose: 3000,
  //     //       hideProgressBar: false,
  //     //       closeOnClick: true,
  //     //       pauseOnHover: true,
  //     //       draggable: true,
  //     //       progress: undefined,
  //     //       theme: "colored",
  //     //       // transition: Bounce,
  //     //     });
  //     //  // }
  //     // }
  //   }
  // }

  console.log(meetingData);
  return (
    <>
      <Header />
      <Sidebar />
      {meetingData.singleMeetingDetails !== null ? (
        <div className="main-content">
          <div className="row">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <div className="meeting-header-text">
                <h4>Meeting Details</h4>
              </div>

              <form className="mt-2 details-form details-form-right">
                {meetingData?.singleMeetingDetails?.parentMeetingDetails
                  ?.length !== 0 ? (
                  <>
                    <div className="form-group ">
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                          <label className="mb-1">Parent Meeting Id</label>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                          <p>
                            {
                              meetingData.singleMeetingDetails
                                ?.parentMeetingDetails[0].meetingId
                            }
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                          <label className="mb-1">Parent Meeting Title</label>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                          <p>
                            {
                              meetingData.singleMeetingDetails
                                ?.parentMeetingDetails[0].title
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="form-group ">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Meeting Id</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>{meetingData.singleMeetingDetails?.meetingId}</p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Title</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>{meetingData.singleMeetingDetails?.title}</p>
                    </div>
                  </div>
                </div>

                <div className="form-group ">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Meeting Mode</label>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <p>{meetingData.singleMeetingDetails?.mode}</p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Location</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>
                        {meetingData?.singleMeetingDetails?.locationDetails
                          .isMeetingRoom === true
                          ? meetingData?.singleMeetingDetails?.roomDetail[0]
                              ?.title +
                            ", " +
                            meetingData?.singleMeetingDetails?.roomDetail[0]
                              .location
                          : meetingData?.singleMeetingDetails?.locationDetails
                              .location}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Meeting Link</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>
                        {meetingData.singleMeetingDetails?.link
                          ? meetingData.singleMeetingDetails?.link
                          : "NA"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Date & Time</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>
                        {meetingData.singleMeetingDetails?.date},
                        {meetingData.singleMeetingDetails?.fromTime} to{" "}
                        {meetingData.singleMeetingDetails?.toTime}{" "}
                      </p>
                      {/* <p>
                        {
                          formatDateTimeFormat(
                            meetingData.singleMeetingDetails?.date
                          ).formattedDate
                        }{" "}
                        ,{meetingData.singleMeetingDetails?.fromTime}{" "}
                        {getTimeSession(
                          meetingData.singleMeetingDetails?.fromTime
                        )}{" "}
                        to {meetingData.singleMeetingDetails?.toTime}{" "}
                        {getTimeSession(
                          meetingData.singleMeetingDetails.toTime
                        )}
                      </p> */}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Status</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <span
                        className={
                          meetingData.singleMeetingDetails?.meetingStatus
                            ?.status === "scheduled" ||
                          meetingData.singleMeetingDetails?.meetingStatus
                            ?.status === "rescheduled"
                            ? "badge bg-success bg-opacity-10 "
                            : meetingData.singleMeetingDetails?.meetingStatus
                                ?.status === "closed"
                            ? "badge bg-primary bg-opacity-10 "
                            : meetingData.singleMeetingDetails?.meetingStatus
                                ?.status === "draft"
                            ? "badge bg-secondary bg-opacity-10 "
                            : "badge bg-danger bg-opacity-10 "
                        }
                      >
                        {meetingData.singleMeetingDetails?.meetingStatus?.status
                          .charAt(0)
                          .toUpperCase() +
                          meetingData.singleMeetingDetails?.meetingStatus?.status.slice(
                            1
                          )}
                      </span>
                      {/* <p>
                        {
                          formatDateTimeFormat(
                            meetingData.singleMeetingDetails?.date
                          ).formattedDate
                        }{" "}
                        ,{meetingData.singleMeetingDetails?.fromTime}{" "}
                        {getTimeSession(
                          meetingData.singleMeetingDetails?.fromTime
                        )}{" "}
                        to {meetingData.singleMeetingDetails?.toTime}{" "}
                        {getTimeSession(
                          meetingData.singleMeetingDetails.toTime
                        )}
                      </p> */}
                    </div>
                  </div>
                </div>

                {meetingData.singleMeetingDetails.attendees.length > 0 ? (
                  <div className="form-group mb-2">
                    <div className="row align-items-center">
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <label className="pb-1"> Attendee(s) </label>
                      </div>
                      <div
                        className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                        // onClick={(e) => {
                        //   setModalStatus(
                        //     true,
                        //     meetingData?.singleMeetingDetails?.attendees
                        //   );
                        // }}
                      >
                        <div className="attendees">
                          {meetingData.singleMeetingDetails.attendees
                            .filter((attendee) => attendee.isEmployee)
                            .map((attendee) => {
                              return (
                                // <div > {customName(attendee.name)}</div>
                                <>
                                  {/* <div>{customName(attendee)}</div> */}
                                  <div className="attendee-list">
                                    {customName(attendee.name)}
                                  </div>
                                </>
                              );
                            })}

                          {/* <p className="m-0">+5 More</p> */}
                          <p className="m-0">
                            {meetingData.singleMeetingDetails.attendees.filter(
                              (attendee) => attendee.isEmployee
                            ).length > 5
                              ? `+${
                                  meetingData.singleMeetingDetails.attendees
                                    .length - 5
                                } More`
                              : null}
                          </p>
                          {meetingData.singleMeetingDetails.attendees.filter(
                            (attendee) => attendee.isEmployee === false
                          ).length > 0 ? (
                            <>
                              {"|        "}
                              {meetingData.singleMeetingDetails.attendees
                                .filter(
                                  (attendee) => attendee.isEmployee === false
                                )
                                .map((attendee) => {
                                  return (
                                    // <div > {customName(attendee.name)}</div>
                                    <>
                                      {/* <div>{customName(attendee)}</div> */}
                                      <div className="attendee-list">
                                        {customName(attendee.name)}
                                      </div>
                                    </>
                                  );
                                })}
                              {/* <p className="m-0">+5 More</p> */}
                              <p className="m-0">
                                {meetingData.singleMeetingDetails.attendees.filter(
                                  (attendee) => attendee.isEmployee === false
                                ).length > 5
                                  ? `+${
                                      meetingData.singleMeetingDetails.attendees
                                        .length - 5
                                    } More`
                                  : null}
                              </p>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <form className="mt-3 details-form-right">
                    <Alert
                      status={"info"}
                      message={"No Attendee Added"}
                      timeoutSeconds={0}
                    />
                    <div className="form-group mb-0 no-image">
                      <NoDataFound dataType={"attendee"} />
                    </div>
                  </form>
                )}
                {meetingData.singleMeetingDetails?.agendasDetail.length !==
                0 ? (
                  <>
                    <div className="minutes-border"></div>
                    <div className="form-group mt-4 agenda">
                      <label className="mb-2">
                        <h4>Agenda(s)</h4>
                      </label>
                      {meetingData.singleMeetingDetails?.agendasDetail
                        .length !== 0 &&
                        meetingData.singleMeetingDetails?.agendasDetail.map(
                          (agenda, index) => {
                            return (
                              <div className="mt-2 agenda-box-border">
                                <label className="mt-1 p-3 topic-head">
                                  {" "}
                                  Agenda {index + 1}
                                </label>

                                <div className="p-3">
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <label className="mt-1 mb-1">
                                          Agenda Title
                                        </label>
                                      </div>
                                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                        <p> {agenda.title}</p>
                                      </div>
                                    </div>
                                  </div>
                                  {agenda.topic !== "" ? (
                                    <div className="form-group">
                                      <div className="row">
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                          <label className="mt-2 topic">
                                            Topic to Discuss
                                          </label>
                                        </div>
                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                          <p className="mb-2">
                                            {agenda.topic}{" "}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className=" form-group">
                                    <div className="row">
                                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <label className="mt-1 mb-1">
                                          Timeline
                                        </label>
                                      </div>
                                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                        <p> {agenda.timeLine} Mins</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </>
                ) : (
                  <>
                    <Alert
                      status={"info"}
                      message={"No Agenda Added"}
                      timeoutSeconds={0}
                    />
                    <div className="mt-3 agenda-box-border no-data-img">
                      <NoDataFound dataType={"agenda"} />
                    </div>
                  </>
                )}
              </form>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <div className="meeting-header-text timeline-div">
                <h4></h4>
                {meetingData?.singleMeetingDetails?.meetingStatus?.status ===
                  "closed" &&
                (meetingData?.singleMeetingDetails?.parentMeetingDetails
                  ?.length !== 0 ||
                  meetingData?.singleMeetingDetails?.parentMeetingId) ? (
                  <Link
                    className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
                    id="open-form-btn"
                    to={`/create-followon/${id}`}
                    state={{
                      meetingId: id,
                      isMeetingDataUpdate: true,
                      step: meetingData,
                    }}
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <p> View Timeline</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-calendar4-week  ms-2 me-0 filter-svg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z" />
                      <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z" />
                    </svg>

                    {/* </button> */}
                  </Link>
                ) : (
                  <Link
                    className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
                    id="open-form-btn"
                    to={`/create-followon/${id}`}
                    state={{
                      meetingId: id,
                      isMeetingDataUpdate: true,
                      step: meetingData,
                    }}
                    style={{
                      textDecoration: "none",
                      color: "black",
                      visibility: "hidden",
                    }}
                  >
                    {/* <button
            className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
            id="open-form-btn"
           // onClick={(e) => setfilter(true)}
          > */}
                    <p> View Timeline</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-pencil-square me-0 filter-svg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                      />
                    </svg>
                    {/* </button> */}
                  </Link>
                )}

                {
                  // meetingData.singleMeetingDetails?.meetingStatus?.status !==
                  // "cancelled"
                  // &&
                  (meetingData.singleMeetingDetails?.meetingStatus?.status ===
                    "scheduled" &&
                    (employeeData?.userData?.isMeetingOrganiser === true ||
                      employeeData?.userData?.isAdmin === true) &&
                    meetingData.singleMeetingDetails?.actionDetail?.length ==
                      0) ||
                  (meetingData.singleMeetingDetails?.meetingStatus?.status ===
                    "rescheduled" &&
                    (employeeData?.userData?.isMeetingOrganiser === true ||
                      employeeData?.userData?.isAdmin === true) &&
                    meetingData.singleMeetingDetails?.actionDetail?.length ===
                      0) ||
                  (meetingData.singleMeetingDetails?.meetingStatus?.status ===
                    "draft" &&
                    (employeeData?.userData?.isMeetingOrganiser === true ||
                      employeeData?.userData?.isAdmin === true) &&
                    meetingData.singleMeetingDetails?.actionDetail?.length ===
                      0) ? (
                    <Link
                      className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
                      id="open-form-btn"
                      to="/edit-meeting"
                      state={{
                        meetingId: id,
                        isMeetingDataUpdate: true,
                        step: meetingData,
                      }}
                      style={{
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      {/* <button
                className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
                id="open-form-btn"
               // onClick={(e) => setfilter(true)}
              > */}
                      <p> Edit</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-pencil-square me-0 filter-svg"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                        />
                      </svg>
                      {/* </button> */}
                    </Link>
                  ) : null
                }
              </div>
              <form className="mt-2 details-form details-form-right">
                {meetingData.singleMeetingDetails?.attendees.length > 0 ? (
                  <div className="form-group mb-2">
                    <AttendeesDetails
                      attendeesData={meetingData.singleMeetingDetails}
                    />
                  </div>
                ) : (
                  <>
                    <Alert
                      status={"info"}
                      message={"No Attendee Added"}
                      timeoutSeconds={0}
                    />
                    <div className="mt-3 agenda-box-border no-data-img">
                      <NoDataFound dataType={"attendee"} />
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      ) : meetingData.singleMeetingDetails === null &&
        !meetingData.loading &&
        !meetingData.isSuccess ? (
        <div className="main-content">
          <div className="row">
            <div className="meeting-header-text">
              <h4>Meeting Details</h4>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <form className="mt-2 details-form details-form-right">
                <div className="row">
                  <Alert
                    status={"info"}
                    message={"No data available."}
                    timeoutSeconds={0}
                  />
                  <NoDataFound dataType={"meeting"} />
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="main-content">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <div className="meeting-header-text">
                <h4>Meeting Details</h4>
              </div>

              <form className="mt-2 details-form details-form-right">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Loader />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewMeetingDetails;

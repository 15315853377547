import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { customName } from "../../helpers/commonHelpers";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import * as constantMessages from "../../constants/constatntMessages";
import { sendMeetingDetails } from "../../redux/actions/meetingActions/MeetingAction";
import LoaderButton from "../Common/LoaderButton";

const ResendMeetingDetailsModal = (props) => {
  const dispatch = useDispatch();
  const meetingDetails = useSelector((state) => state.meeting);
  const accessToken = localStorage.getItem("accessToken");
  const meetingData = props.meetingDetail;
  const attendeesArray = meetingData?.attendees;
  const employeeData = useSelector((state) => state.user);
  const [attendeesFinalArray, setAttendeesFinalArray] = useState([]);
  console.log(props);
  const closeModal = () => {
    // setErrors({});
    // setFormData({
    //   ...formData,
    //   remarks: "",
    // });
    props.setIsResendMeetingDetailsModalOpen(false);
  };

  useEffect(() => {
    if (
      meetingDetails?.isSuccess === true &&
      meetingDetails?.loading === false
    ) {
      props.setIsResendMeetingDetailsModalOpen(false);
      setAttendeesFinalArray([]);
    }
    return () => {
      // Anything in here is fired on component unmount.
      console.log("component un mount");
      // dispatch(updateIsSuccess(false));
    };
  }, [meetingDetails?.isSuccess, meetingDetails?.loading]);

  const handleSubmit = () => {
    if (attendeesFinalArray.length === 0) {
      toast.warning(constantMessages.selectAttendee, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        // transition: Bounce,
      });
    } else {
      const payload = {
        meetingId: meetingData._id,
        attendees: attendeesFinalArray,
      };
      dispatch(sendMeetingDetails(payload, accessToken));
    }
  };

  const checkHandler = (e, attendeeId) => {
    const { checked } = e.target;
    console.log(attendeeId, checked);
    if (attendeeId === "all") {
      if (attendeesArray.length === attendeesFinalArray.length) {
        setAttendeesFinalArray([]);
      } else {
        const modifiedAttendees = attendeesArray.map((obj) => {
          return obj._id;
        });
        setAttendeesFinalArray([...modifiedAttendees]);
      }
    } else {
      if (attendeeId) {
        if (attendeesFinalArray.includes(attendeeId)) {
          const modifiedAttendees = attendeesFinalArray.filter(
            (item) => item !== attendeeId
          );
          setAttendeesFinalArray([...modifiedAttendees]);
        } else {
          // const modifiedAttendees = attendeesArray.map((obj) => {
          //   if (obj._id === attendeeId) {
          //     return obj._id;
          //   }
          //   return null;
          setAttendeesFinalArray([...attendeesFinalArray, attendeeId]);

          // console.log(modifiedAttendees);
        }

        // return obj;
      }
    }

    // dispatch(setFinalMinuteData(modifiedMinutes));
  };
  //  else {
  //   setFormData({
  //     ...formData,
  //     isAction: !formData.isAction,
  //   });
  // }
  console.log(meetingDetails);
  console.log(attendeesFinalArray);
  return (
    <Modal show={props.isResendMeetingDetailsModalOpen} onHide={closeModal}>
      <div className="modal-header">
        <h4 className="modal-title">Resend Meeting Details</h4>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={closeModal}
        ></button>
      </div>
      <div className="modal-body delete-txt">
        <div className="mb-0">
          <div className="row">
            <div className="attendees-popup">
              <div className="check-attendee">
                <input
                  type="checkbox"
                  name="selectedAttendee"
                  id=""
                  className="form-check-input"
                  onChange={(e) => checkHandler(e, "all")}
                  //  checked={formData.isAction}
                  // onChange={(e) => checkHandler(e, minute.uid)}
                  // checked={minute.isAction}
                  //   checked={
                  //     attendeesFinalArray.includes(
                  //       attendee?._id?.toString()
                  //     )
                  //       ? true
                  //       : false
                  // // attendee._id.toString() ===
                  // // employeeData.userData._id.toString()
                  // attendee?._id?.toString() ===
                  //   props?.meetingData?.createdById?.toString() ||
                  // (attendee?._id?.toString() ===
                  //   employeeData.userData._id.toString() &&
                  //   employeeData.userData.isAdmin === true) ||
                  // (attendee?._id?.toString() ===
                  //   employeeData.userData._id.toString() &&
                  //   employeeData.userData.isMeetingOrganiser ===
                  //     true)
                  //   ? true
                  //   : attendee.canWriteMOM
                  // }
                />
                <p> Select All</p>
              </div>
              <div className="minutes-border"></div>
              {attendeesArray &&
                attendeesArray.map((attendee, index) => {
                  return (
                    <div className="attendee-content" key={index}>
                      <div className="check-attendee">
                        <input
                          type="checkbox"
                          name="selectedAttendee"
                          id=""
                          className="form-check-input"
                          onChange={(e) => checkHandler(e, attendee._id)}
                          //  checked={formData.isAction}
                          // onChange={(e) => checkHandler(e, minute.uid)}
                          // checked={minute.isAction}
                          checked={
                            attendeesFinalArray.includes(
                              attendee?._id?.toString()
                            )
                              ? true
                              : false
                            // // attendee._id.toString() ===
                            // // employeeData.userData._id.toString()
                            // attendee?._id?.toString() ===
                            //   props?.meetingData?.createdById?.toString() ||
                            // (attendee?._id?.toString() ===
                            //   employeeData.userData._id.toString() &&
                            //   employeeData.userData.isAdmin === true) ||
                            // (attendee?._id?.toString() ===
                            //   employeeData.userData._id.toString() &&
                            //   employeeData.userData.isMeetingOrganiser ===
                            //     true)
                            //   ? true
                            //   : attendee.canWriteMOM
                          }
                        />
                        <div className="attendee1 attendee-list sl">
                          {" "}
                          {customName(attendee.name)}
                        </div>
                      </div>

                      <div className="action-cmnt-text">
                        <p className="detail-name">
                          {" "}
                          {attendee?._id?.toString() ===
                          props?.meetingData?.createdById?.toString()
                            ? attendee.name + " (Organiser)"
                            : attendee?._id?.toString() ===
                                employeeData.userData._id.toString() &&
                              employeeData.userData.isAdmin === true
                            ? attendee.name + " (Admin)"
                            : attendee.name}
                        </p>
                        {/* body > ngb-modal-window > div > div > div.modal-body.attendees-popup > div:nth-child(1) > div.action-cmnt-text >  */}
                        {/* <p className="name-undertext comment-text">
                          {attendee.email}
                        </p> */}
                        {attendee?._id?.toString() ===
                          props?.meetingData?.createdById?.toString() &&
                        employeeData.userData.isAdmin === true ? (
                          <p className="name-undertext comment-text">
                            {attendee.email}
                          </p>
                        ) : (
                          <p className="name-undertext comment-text">
                            {attendee.email}{" "}
                            {attendee.empId
                              ? " (" + attendee.empId + ") "
                              : " (Guest)"}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        {/* <button
        type="button"
        onClick={closeModal}
        className="reset btn btn-light bg-white border-primary text-primary "
      >
        <p>Close</p>
      </button> */}

        {meetingDetails.loading ? (
          <LoaderButton />
        ) : (
          <button
            type="button"
            onClick={handleSubmit}
            className="Mom-btn btn btn-secondary bg-primary border-primary"
          >
            <p>Send</p>
          </button>
        )}

        {/* <button
          type="button"
          onClick={handleSubmit}
          className="Mom-btn btn btn-secondary bg-primary border-primary"
        >
          <p>Send</p>
        </button> */}
      </div>
    </Modal>
  );
};

export default ResendMeetingDetailsModal;

import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import * as constantMessages from "../../constants/constatntMessages";
import { rescheduleMeeting } from "../../redux/actions/meetingActions/MeetingAction";
import { checkCharacterLeft } from "../../helpers/commonHelpers";
const regularExpression = /^[0-9a-zA-Z .(),/-]+$/;
const RescheduleMeetingModal = (props) => {
  console.log(props.meetingData);
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const { meetingData, isFetchedMeetingList } = props;
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    date: "",
    fromTime: "",
    toTime: "",
    remarks: "",
  });

  useEffect(() => {
    console.log(" useeffect--------------->>>>>>>>>>>>>>1");

    if (meetingData?.date) {
      var date = new Date(meetingData?.date);
      var dateString = new Date(
        date?.getTime() - date?.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
    }

    if (meetingData) {
      console.log("in------------444", meetingData);
      setFormData({
        ...formData,
        // fromTime:
        //   parseInt(meetingData?.fromTime?.split(" ")[0].split(":")[0]) < 10 && parseInt(meetingData?.fromTime?.split(" ")[0].split(":")[0]) > 0
        //     ? `0${meetingData?.fromTime?.split(" ")[0].split(":")[0]}:${
        //         meetingData?.fromTime?.split(" ")[0].split(":")[1]
        //       }`
        //     : meetingData?.fromTime?.split(" ")[0],
        // toTime:
        // parseInt(meetingData?.toTime?.split(" ")[0].split(":")[0]) < 10 && parseInt(meetingData?.toTime?.split(" ")[0].split(":")[0]) > 0
        //     ? `0${meetingData?.toTime?.split(" ")[0].split(":")[0]}:${
        //         meetingData?.toTime?.split(" ")[0].split(":")[1]
        //       }`
        //     : meetingData?.toTime?.split(" ")[0],
        date: dateString ? dateString : null,
        toTime: meetingData?.actualToTime,
        fromTime: meetingData?.actualFromTime,
      });
    }

    if (isFetchedMeetingList === true) {
      setErrors({});
      setFormData({
        ...formData,
        date: "",
        fromTime: "",
        toTime: "",
        remarks: ""
      
      });
      props.setIsRescheduleModalOpen(false);
    }

    return () => {
      console.log("return useeffect--------------->>>>>>>>>>>>>>");
      setErrors({});
      setFormData({
        ...formData,
        date: "",
        fromTime: "",
        toTime: "",
        remarks: "",
      });
    };
  }, [meetingData, isFetchedMeetingList]);

  const handleSubmit = () => {
    const newErrors = validateForm(formData);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const payload = {
        ...formData,
        attendees: meetingData.attendees.map(
          ({ rsvp, isAttended,empId, ...keepAttrs }) => keepAttrs
        ),
      };
      console.log(payload);
      dispatch(rescheduleMeeting(meetingData._id, payload, accessToken));
    }
  };

  const closeModal = () => {
    setErrors({});
    setFormData({
      ...formData,
      remarks: "",
    });
    props.setIsRescheduleModalOpen(false);
  };

  const validateForm = (data) => {
    console.error(data);
    const errors = {};

    if (!data.date.trim()) {
      errors.date = constantMessages.dateRequired;
    } else if (data.date.trim()) {
      // // const currentDate = new Date();
      // // const inputDate = new Date(data.date);
      // // let differenceInTime = inputDate.getTime() - currentDate.getTime();
      // // let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      // const currentDate = new Date();
      // const inputDate = new Date(formData.date);
      // // let differenceInDays = inputDate.getDate() - currentDate.getDate();
      // // // let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      // // console.log(inputDate);
      // // console.log(currentDate);
      // let differenceInDays = inputDate.getTime() - currentDate.getTime();
      // // let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      // console.log(inputDate.getTime());
      // console.log(currentDate.getTime());
      // console.log(differenceInDays);
      // if (differenceInDays < 0) {
      //   errors.date = constantMessages.invalidDate;
      // }
      
   
        const currentDate = new Date();
        const inputDate = new Date(formData.date);
        let differenceInTime = inputDate.getTime() - currentDate.getTime();
        let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
        differenceInDays =
          differenceInDays === -1 ? differenceInDays + 1 : differenceInDays;
        console.log(differenceInDays);
        if (differenceInDays < 0) {
          errors.date = constantMessages.invalidDate;
        }
     // }
     // setErrors(errors);


    }
    setErrors(errors);
    if (!data.fromTime.trim()) {
      errors.fromTime = constantMessages.timeRequired;
    } else if (formData.toTime.trim()) {
      console.log(formData);
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      console.log(fromTimeHour);
      console.log(fromTimeMinute);
      console.log(toTimeHour);
      console.log(toTimeMinute);
      if (fromTimeHour > toTimeHour) {
        errors.fromTime = constantMessages.invalidFromHour;
      } else if (fromTimeHour === toTimeHour && fromTimeMinute > toTimeMinute) {
        errors.fromTime = constantMessages.invalidFromMinute;
      }
    }
    if (!data.toTime.trim()) {
      errors.toTime = constantMessages.timeRequired;
    } else if (formData.fromTime.trim()) {
      console.log(formData);
      const currentTime = new Date().getTime();
      console.log(currentTime);
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];
      console.log(fromTimeHour);
      console.log(fromTimeMinute);
      console.log(toTimeHour);
      console.log(toTimeMinute);
      // if (currentTime >>= fromTimeHour) {

      // }
      if (fromTimeHour > toTimeHour) {
        errors.toTime = constantMessages.invalidToHour;
      } else if (
        fromTimeHour === toTimeHour &&
        fromTimeMinute >= toTimeMinute
      ) {
        errors.toTime = constantMessages.invalidToMinute;
      }
    }

    if (!formData.remarks.trim()) {
      errors.remarks = constantMessages.reasonRequired;
    } else if (!regularExpression.test(formData.remarks.trim())) {
      errors.remarks = constantMessages.titleRegexError;
    } else if (
      formData.remarks.trim().length < 5 ||
      formData.remarks.trim().length > 100
    ) {
      errors.remarks = constantMessages.stringLengthError;
    }

    return errors;
  };

  const fromDateFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.fromTime.trim()) {
      errors.fromTime = constantMessages.timeRequired;
    } else if (formData.toTime.trim()) {
      console.log(formData);
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      console.log(fromTimeHour);
      console.log(fromTimeMinute);
      console.log(toTimeHour);
      console.log(toTimeMinute);
      if (fromTimeHour > toTimeHour) {
        errors.fromTime = constantMessages.invalidFromHour;
      } else if (
        fromTimeHour === toTimeHour &&
        fromTimeMinute >= toTimeMinute
      ) {
        errors.fromTime = constantMessages.invalidFromMinute;
      }
    }
    setErrors(errors);
  };

  const toDateFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.toTime.trim()) {
      errors.toTime = constantMessages.timeRequired;
    } else if (formData.fromTime.trim()) {
      console.log(formData);
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];
      console.log(fromTimeHour);
      console.log(fromTimeMinute);
      console.log(toTimeHour);
      console.log(toTimeMinute);
      if (fromTimeHour > toTimeHour) {
        errors.toTime = constantMessages.invalidToHour;
      } else if (
        fromTimeHour === toTimeHour &&
        fromTimeMinute >= toTimeMinute
      ) {
        errors.toTime = constantMessages.invalidToMinute;
      }
    }
    setErrors(errors);
  };

  // const dateFieldValidationCheck1= (e) => {
  //   const errors = {};
  //   if (!formData.date.trim()) {
  //     errors.date = constantMessages.dateRequired;
  //   } else if (formData.date.trim()) {
  //     const currentDate = new Date();
  //     const inputDate = new Date(formData.date);
  //     let differenceInDays = inputDate.getTime() - currentDate.getTime();
  //     // let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
  //     console.log(inputDate.getTime());
  //     console.log(currentDate.getTime());
  //     console.log(differenceInDays);
  //     if (differenceInDays < 0) {
  //       errors.date = constantMessages.invalidDate;
  //     }
  //   }
  //   setErrors(errors);
  // };

  const dateFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.date.trim()) {
      errors.date = constantMessages.dateRequired;
    } else if (formData.date.trim()) {
      const currentDate = new Date();
      const inputDate = new Date(formData.date);
      let differenceInTime = inputDate.getTime() - currentDate.getTime();
      let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      differenceInDays =
        differenceInDays === -1 ? differenceInDays + 1 : differenceInDays;
      console.log(differenceInDays);
      if (differenceInDays < 0) {
        errors.date = constantMessages.invalidDate;
      }
    }
    setErrors(errors);
  };

  const handleChange = (e) => {
    // dispatch(updateStep(1))
    setErrors({});
    //  dispatch(updateOtpProcessed(false));
    //  console.log("9999999999999999999999999999999999999", authData);
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateRemarks = () => {
    const errors = {};
    if (!formData.remarks.trim()) {
      errors.remarks = constantMessages.reasonRequired;
    }
    setErrors(errors);
    return errors;
  };

  console.log(formData);
  console.log(meetingData);

  return (
    <Modal show={props.isRescheduleModalOpen} onHide={closeModal}>
      <div className="modal-header">
        <h4 className="modal-title">Reschedule Meeting</h4>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={closeModal}
        ></button>
      </div>
      <div className="modal-body delete-txt">
        <div className="mb-0">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="position-relative resp-input-margin-bottom">
                <label className="mb-1 input-date">
                  Date
                  <span className="star-mark">*</span>
                </label>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  onBlur={dateFieldValidationCheck}
                />
                {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#fff"
                      className="bi bi-calendar3 calender"
                      viewBox="0 0 16 16"
                    >
                      <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z" />
                      <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                    </svg> */}
             
              {errors.date && (
                <span className="error-message">{errors.date}</span>
              )} 
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="position-relative resp-input-margin-bottom">
                <label className="mb-1">
                  {" "}
                  From Time
                  <span className="star-mark">*</span>
                </label>
                <input
                  type="time"
                  className="input-time"
                  name="fromTime"
                  value={formData.fromTime}
                  //value="07:30"
                  onChange={handleChange}
                  onBlur={fromDateFieldValidationCheck}
                />
                {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#fff"
                      className="bi bi-stopwatch-fill time"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07A7.001 7.001 0 0 0 8 16a7 7 0 0 0 5.29-11.584l.013-.012.354-.354.353.354a.5.5 0 1 0 .707-.707l-1.414-1.415a.5.5 0 1 0-.707.707l.354.354-.354.354-.012.012A6.97 6.97 0 0 0 9 2.071V1h.5a.5.5 0 0 0 0-1zm2 5.6V9a.5.5 0 0 1-.5.5H4.5a.5.5 0 0 1 0-1h3V5.6a.5.5 0 1 1 1 0" />
                    </svg> */}
             
              {errors.fromTime && (
                <span className="error-message">{errors.fromTime}</span>
              )}
               </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="position-relative resp-input-margin-bottom">
                <label className="mb-1">
                  To Time
                  <span className="star-mark">*</span>
                </label>
                <input
                  type="time"
                  className="input-time2"
                  name="toTime"
                  value={formData.toTime}
                  onChange={handleChange}
                  onBlur={toDateFieldValidationCheck}
                />
                {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#fff"
                      className="bi bi-stopwatch-fill time2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07A7.001 7.001 0 0 0 8 16a7 7 0 0 0 5.29-11.584l.013-.012.354-.354.353.354a.5.5 0 1 0 .707-.707l-1.414-1.415a.5.5 0 1 0-.707.707l.354.354-.354.354-.012.012A6.97 6.97 0 0 0 9 2.071V1h.5a.5.5 0 0 0 0-1zm2 5.6V9a.5.5 0 0 1-.5.5H4.5a.5.5 0 0 1 0-1h3V5.6a.5.5 0 1 1 1 0" />
                    </svg> */}
             
              {errors.toTime && (
                <span className="error-message">{errors.toTime}</span>
              )} 
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-12 delete-txt">
              <label className="mb-1 input-date">
                Reason
                <span className="star-mark">*</span>
              </label>

              <textarea
                cols="40"
                rows="4"
                id="remark"
                name="remarks"
                onBlur={validateRemarks}
                onChange={handleChange}
                value={formData.remarks}
                placeholder="Enter your reason here..."
                required
              ></textarea>
              <p className="success-message">
                {checkCharacterLeft(formData.remarks)} Characters left
              </p>
              {errors.remarks && (
                <span className="error-message">{errors.remarks}</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        {/* <button
          type="button"
          onClick={closeModal}
          className="reset btn btn-light bg-white border-primary text-primary "
        >
          <p>Close</p>
        </button> */}
        <button
          type="button"
          onClick={handleSubmit}
          className="Mom-btn btn btn-secondary bg-primary border-primary"
        >
          <p>Reschedule</p>
        </button>
      </div>
    </Modal>
  );
};

export default RescheduleMeetingModal;

import React, { useState, useEffect } from "react";
import "../../../App.css";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import MeetingHeader from "../../Common/Header/MeetingHeader";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button, Table, Dropdown, Form } from "react-bootstrap";
import axios from "../../../../node_modules/axios/index";
import Loader from "../../Common/Loader";
import LoaderButton from "../../Common/LoaderButton";
import NoDataFound from "../../Common/NoDataFound";
import "react-toastify/dist/ReactToastify.css";
import Alert from "../../Common/Alert";
import { FormControl } from "../../../../node_modules/@mui/material/index";
import CustomizedSwitches from "./switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import DesignationDropdown from "./DesignationDropdown";
import DepartmentDropdown from "./DepartmentDropdown";
import UnitDropdown from "./UnitDropdown";
import EditDesignationDropdown from "./EditDesignationDropdown";
import EditDepartmentDropdown from "./EditDepartmentDropdown";
import EditUnitDropdown from "./EditUnitDropdown";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { logOut } from "../../../redux/actions/authActions/authAction";
import constatntMessages from "../../../constants/constatntMessages";

// import EditDesignationDropdown from "./editDesignationDropdown";
// import EditEmployeeModal from "./EditEmployeeModal";
// import Form from 'react-bootstrap/Form';

const Employee = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  // const userId = userData?._id;
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (authData.isInValidUser) {
    console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const accessToken = localStorage.getItem("accessToken");
  const organizationId = userData?.organizationId;
  const [isGetApiRes, setIsGetApiRes] = useState(false);
  //Create Employee
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [units, setUnits] = useState([]);
  const [formValues, setFormValues] = useState({
    name: "",
    empId: "",
    email: "",
    designationId: "",
    departmentId: "",
    unitId: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [apiResData, setApiResData] = useState({
    isSuccess: false,
    message: "",
  });
  const [isValid, setIsValid] = useState(false);
  // console.log("masterDataaaaaaa--->>>", masterData);
  //Editttttt
  const [name, setName] = useState("");
  console.log(name);
  const [empId, setEmpId] = useState("");
  const [email, setEmail] = useState("");
  const [designationId, setDesignationId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [isAdmin, setIsAdmin] = useState();
  const [isMeetingOrganiser, setIsMeetingOrganiser] = useState();
  //List Employee
  const [employees, setEmployees] = useState([]);
  console.log("Status-");
  // const d = employees.map((emp) => {
  //   console.log(emp.isActive);
  // });

  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [order, setOrder] = useState(-1);
  const [isFetching, setIsFetching] = useState(false);

  const [employeeData, setEmployeeData] = useState({
    name: "",
    organizationId: organizationId,
    empId: "",
    email: "",
    designationId: "",
    departmentId: "",
    unitId: "",
    isMeetingOrganiser: false,
    isAdmin: false,
  });

  //DELETE Employee
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  //EDIT Employee
  const [showEditModal, setShowEditModal] = useState(false);
  const [editEmployeeData, setEditEmployeeData] = useState({});
  console.log(editEmployeeData);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [editName, setEditName] = useState(employeeData.name || "");
  const [editEmpId, setEditEmpId] = useState(employeeData.empId || "");
  const [editEmail, setEditEmail] = useState(employeeData.email || "");
  console.log(editEmail);
  const [editIsAdmin, setEditIsAdmin] = useState(employeeData.isAdmin || false);
  const [editIsMeetingOrganiser, setEditIsMeetingOrganiser] = useState(
    employeeData.isMeetingOrganiser || false
  );
  console.log(editIsMeetingOrganiser);
  const [editDesignationId, setEditDesignationId] = useState(
    employeeData?.designationId || ""
  );

  const [editDepartmentId, setEditDepartmentId] = useState(
    employeeData?.departmentId || ""
  );
  const [editUnitId, setEditUnitId] = useState(employeeData.unitId || "");
  // const [editIsMeetingOrganiser, setEditIsMeetingOrganiser] = useState(
  //   employeeData?.isMeetingOrganiser || false
  // );
  const [editIsEmployee, setEditIsEmployee] = useState(
    employeeData?.isEmployee || false
  );
  const [isActive, setIsActive] = useState(employeeData?.isActive || false);

  //Viewe Employee
  const [showViewModal, setShowViewModal] = useState(false);
  const [viewEmployee, setViewEmployee] = useState({
    name: "",
    organizationId: organizationId,
    empId: "",
    email: "",
    designationId: "",
    departmentId: "",
    unitId: "",
    isAdmin: false,
    isMeetingOrganiser: false,
    isActive: true,
  });
  console.log("setSelectedEmployee", setSelectedEmployee);

  const isValidate = () => {
    let isValid = true;
    const errors = {};

    // Name Validation
    const excludeHtmlForName = /^[0-9a-zA-Z ,&,/-]+$/;
    if (!employeeData.name.trim()) {
      errors.name = "Employee Name is required";
      isValid = false;
    } else if (!excludeHtmlForName.test(employeeData.name)) {
      errors.name = constatntMessages.invalidInput;
      isValid = false;
    } else if (
      employeeData.name.trim().length < 2 ||
      employeeData.name.trim().length > 100
    ) {
      errors.name = "Name must be between 2-100 characters long";
      isValid = false;
    }

    // Employee ID Validation
    const empIdValidation = /^[0-9a-zA-Z -.(),-,_/]+$/;
    const trimmedEmpId = employeeData.empId.trim();
    if (!trimmedEmpId) {
      errors.empId = "Employee ID is required";
      isValid = false;
    } else if (!empIdValidation.test(employeeData.empId)) {
      errors.empId = constatntMessages.invalidInput;
      isValid = false;
    } else if (
      employeeData.empId.trim().length < 2 ||
      employeeData.empId.trim().length > 20
    ) {
      errors.empId = "Employee ID must be  2 - 20  characters";
      isValid = false;
    }

    // Email Validation
    const emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!employeeData.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!emailValidation.test(employeeData.email)) {
      errors.email = "Please enter a valid email address";
      isValid = false;
    }

    // Designation ID Validation
    if (!employeeData.designationId.trim()) {
      errors.designationId = "Designation is required";
      isValid = false;
    }

    console.log("Department ID:", employeeData.departmentId);
    if (!employeeData.departmentId || !employeeData.departmentId.trim()) {
      errors.departmentId = "Department is required";
      isValid = false;
    }

    // Unit ID Validation
    if (!employeeData.unitId.trim()) {
      errors.unitId = "Unit is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  //Fetch mod
  const [selectedOption, setSelectedOption] = useState([
    { label: "Select Designation(s)", value: "" },
  ]);
  const [selectedDepartmentOption, setSelectedDepartmentOption] = useState([
    { label: "Select Department(s)", value: "" },
  ]);
  const [selectedUnitOption, setSelectedUnitOption] = useState([
    { label: "Select Unit(s)", value: "" },
  ]);
  console.log(selectedOption[0].label);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [selectedUnit, SetSelectedUnit] = useState(null);
  const [searchData, setSearchData] = useState({
    unit: "",
    department: "",
    designation: "",
  });
  const fetchMasterData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/employee/masterData/${organizationId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
        }
      );
      console.log("response-->", response.data.data);
      if (response.data.success) {
        const { departmentList, designationList, unitList } =
          response.data.data;
        setDepartments(departmentList || []);
        setDesignations(designationList || []);
        setUnits(unitList || []);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching master data:", error);
      toast.error("Error fetching master data");
    }
  };

  console.log(editIsMeetingOrganiser);
  console.log(isMeetingOrganiser);
  // useEffect(() => {
  //   fetchMasterData();
  // }, []);

  useEffect(() => {
    document.title = "Employee: MinutesVault";
    console.log("inside");
    if (!accessToken) {
      navigate("/login");
    }
    //  setSelectedOption()
    setSearchData({});
    setSelectedOption([
      {
        label: employeeData?.designationId,
        value: employeeData?.name,
      },
    ]);
    setSelectedDepartmentOption([
      {
        label: employeeData?.departmentId,
        value: employeeData?.name,
      },
    ]);
    setSelectedUnitOption([
      {
        label: employeeData?.unitId,
        value: employeeData?.name,
      },
    ]);
    setEmployeeData({
      ...employeeData,
      isMeetingOrganiser: employeeData?.isMeetingOrganiser,
      isAdmin: employeeData?.isAdmin,
    });
    fetchMasterData();
  }, []);

  const handleDesignationChange = (e) => {
    console.log(e);
    setDesignationId(e.value);
  };

  const handleDepartmentChange = (e) => {
    console.log(e);
    setDepartmentId(e.value);
  };

  const handleUnitChange = (e) => {
    console.log(e);
    setUnitId(e.value);
  };
  console.log(editEmployeeData[0]);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setErrors({});

    if (name === "isMeetingOrganiser" && type === "checkbox") {
      if (checked) {
        setEmployeeData({
          ...employeeData,
          isMeetingOrganiser: true,
          isAdmin: false,
        });
      } else {
        setEmployeeData({
          ...employeeData,
          isMeetingOrganiser: false,
        });
      }
    } else if (name === "isAdmin" && type === "checkbox") {
      if (checked) {
        setEmployeeData({
          ...employeeData,
          isMeetingOrganiser: false,
          isAdmin: true,
        });
      } else {
        setEmployeeData({
          ...employeeData,
          isAdmin: false,
        });
      }
    } else {
      setEmployeeData({
        ...employeeData,
        [name]: type === "checkbox" ? checked : value,
      });
    }

    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
    setEditEmployeeData({
      ...editEmployeeData,
      [name]: type === "checkbox" ? checked : value,
    });

    // Reset errors for all fields
    setErrors({
      ...errors,
      [name]: "",
      empId: "",
      email: "",
      designationId: "",
      departmentId: "",
      unitId: "",
    });
  };

  const editHandleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setErrors({});

    if (type === "checkbox") {
      if (name === "editIsMeetingOrganiser" && checked) {
        setEditEmployeeData({
          ...employeeData,
          editIsMeetingOrganiser: true,
          editIsAdmin: false,
        });
        console.log(name, checked);
        setEditIsMeetingOrganiser(true);
        setEditIsAdmin(false);
      } else if (name === "editIsAdmin" && checked) {
        setEditEmployeeData({
          ...employeeData,
          editIsMeetingOrganiser: false,
          editIsAdmin: true,
        });
        setEditIsMeetingOrganiser(false);
        setEditIsAdmin(true);
      } else if (!checked) {
        setEditEmployeeData({
          ...employeeData,
          editIsMeetingOrganiser: false,
          editIsAdmin: false,
        });
        setEditIsMeetingOrganiser(false);
        setEditIsAdmin(false);
      }
    } else {
      setEditEmployeeData({
        ...editEmployeeData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  // This function updates the errors state without resetting it
  const updateErrors = (field, message) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (message) {
        newErrors[field] = message;
      } else {
        delete newErrors[field];
      }
      return newErrors;
    });
  };

  const nameValidationCheck = () => {
    const excludeHtml = /^[0-9a-zA-Z ,]+$/;

    if (!employeeData.name.trim()) {
      updateErrors("name", "Employee Name is required");
    } else if (!excludeHtml.test(employeeData.name)) {
      updateErrors("name", constatntMessages.invalidInput);
    } else if (
      employeeData.name.trim().length < 2 ||
      employeeData.name.trim().length > 100
    ) {
      updateErrors("name", "Name must be  2 - 100  characters");
    } else {
      updateErrors("name", null);
    }
  };

  const emailValidationCheck = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!employeeData.email.trim()) {
      updateErrors("email", "Email is required");
    } else if (!emailRegex.test(employeeData.email)) {
      updateErrors("email", "Invalid email format");
    } else {
      updateErrors("email", null);
    }
  };

  const empIdValidationCheck = () => {
    const excludeHtml = /^[0-9a-zA-Z -.(),-,_/]+$/;

    if (!employeeData.empId.trim()) {
      updateErrors("empId", "Employee ID is required");
    } else if (!excludeHtml.test(employeeData.empId)) {
      updateErrors("empId", constatntMessages.invalidInput);
    } else if (
      employeeData.empId.trim().length < 2 ||
      employeeData.empId.trim().length > 20
    ) {
      updateErrors("empId", "Employee ID must be  2 - 20  characters");
    } else {
      updateErrors("empId", null);
    }
  };

  const designationValidationCheck = () => {
    if (!employeeData.designationId.trim()) {
      updateErrors("designationId", "Designation is is required");
    } else {
      updateErrors("designationId", null);
    }
  };

  //Create EMPLOYEE
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!isValidate()) {
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/employee/createEmployee`,
        employeeData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
        }
      );

      if (response.data.success) {
        // Reset the form and display success message
        setEmployeeData({
          name: "",
          empId: "",
          email: "",
          designationId: "",
          departmentId: "",
          unitId: "",
          isMeetingOrganiser: false,
          isAdmin: false,
          organizationId: organizationId,
        });

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        fetchEmployeeData();
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.error("Error creating employee:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //Fetch Employee
  const fetchEmployees = async (bodyData) => {
    try {
      setIsFetching(true);
      const headerObject = {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
        params: { limit, page, order },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V1/employee/listEmployee`,
        bodyData,
        headerObject
      );
      // const data = response.data.data || {};
      // console.log("Main data-->", data.employeeData);
      // setEmployeeData(data);
      const data = response.data.data || {};
      console.log(data);
      setEmployees(data.employeeData);
      setTotalCount(data.totalCount || 0);
    } catch (error) {
      console.error("Error fetching employees:", error);
      setLoading(false);
    } finally {
      setIsFetching(false);
    }
  };
  useEffect(() => {
    fetchEmployeeData();
  }, [searchKey, page, limit, order]);
  const handleSearch = (event) => {
    setSearchKey(event.target.value);
    setPage(1);
  };
  const fetchEmployeeData = async () => {
    const bodyData = searchKey
      ? { searchKey, organizationId }
      : { organizationId };
    console.log("bodyData-->", bodyData);
    await fetchEmployees(bodyData);
  };
  const handleRowsPerPageChange = (event) => {
    setLimit(event.target.value);
    setPage(1);
  };
  // const fromDataCount = employees?.length === 0 ? 0 : (page - 1) * limit + 1;
  // const toDataCount = (page - 1) * limit + (employees?.length || 0);
  // const totalOption = Math.round(totalCount / 5 );
  // const totalPage = Math.round(totalCount / limit );
  // const totalPageArray = Array(totalPage).fill();

  const fromDataCount = employees?.length === 0 ? 0 : (page - 1) * limit + 1;
  const toDataCount = (page - 1) * limit + employees?.length;
  const totalOption = Math.round((totalCount % 5) + 0.5);
  const totalPage = Math.ceil(totalCount / limit);
  const totalPageArray = Array.from({ length: totalPage }, (_, i) => i + 1);
  const maxOption = Math.ceil(totalCount / 5) * 5;

  const handleClearSearch = () => {
    setSearchKey("");
    setPage(1);
    fetchEmployeeData();
  };
  // const formatDateTimeFormat = (date) => {
  //   const sourceDate = new Date(date).toDateString();
  //   const sourceTime = new Date(date).toLocaleTimeString();
  //   const [, month, day, year] = sourceDate.split(" ");
  //   const formattedDate = [day, month, year].join(" ");
  //   const [hour, minute] = sourceTime.split(":");
  //   const formattedTime = `${hour}:${minute} ${sourceTime.split(" ")[1]}`;

  //   console.log("formattedTime-->", formattedTime);
  //   return { formattedTime, formattedDate };
  // };

  //DELETE EMPLOYEE
  const formatDateTimeFormat = (date) => {
    const dateInDFormat = new Date(date);
    // Get day, month, and year
    let day = dateInDFormat.getUTCDate();
    const month = dateInDFormat.toLocaleString("en-US", { month: "short" });
    const year = dateInDFormat.getUTCFullYear();
    let dayFormatted = day < 10 ? "0" + day : day;
    // Get hours and minutes in 12-hour format
    let hours = dateInDFormat.getUTCHours();
    const minutes = dateInDFormat.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    // Format minutes
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedDate = `${dayFormatted} ${month} ${year}`;
    const formattedTime = `${hours}:${formattedMinutes} ${ampm}`;
    return { formattedTime, formattedDate };
  };
  const handleDeleteClick = (employee) => {
    setEmployeeToDelete(employee);
    setShowDeleteModal(true);
  };
  const handleDeleteConfirm = async () => {
    try {
      if (employeeToDelete) {
        // console.log("which Id--->>>>>", departmentToDelete?._id);
        await deleteEmployee(employeeToDelete?._id);
        setShowDeleteModal(false);
        setEmployeeToDelete(null);
      }
    } catch (error) {
      console.error("Error while deleting unit:", error);
    }
  };
  const deleteEmployee = async (employeeId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/V1/employee/deleteEmployee/${employeeId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
        }
      );
      if (response.data.success) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
        const totalRemainingItems = totalCount - 1;
        const maxPages = Math.ceil(totalRemainingItems / limit);
        if (page > maxPages) {
          setPage(page - 1);
        }
        fetchEmployeeData();
        return response.data;
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        // transition: Bounce,
      });
      console.error("Error deleting Employee:", error);
      throw error;
    }
  };

  //Update Employee

  useEffect(() => {
    // setName(employeeData.name || "");
    setEmpId(employeeData.empId || "");
    setEmail(employeeData.email || "");
    setDesignationId(employeeData.designationId || "");
    setDepartmentId(employeeData.departmentId || "");
    setUnitId(employeeData.unitId || "");
    setEditIsAdmin(employeeData.isAdmin || false);
    console.log(employeeData);
    setEditIsMeetingOrganiser(employeeData.isMeetingOrganiser || false);
    console.log(employeeData);
    console.log(departments);

    setSelectedDesignation({
      label: designations.find((dep) => dep?._id === employeeData.designationId)
        ?.name,
      value: designations.find((dep) => dep?._id === employeeData.designationId)
        ?._id,
    });
    setSelectedDepartment({
      label: departments.find((dep) => dep?._id === employeeData.departmentId)
        ?.name,
      value: departments.find((dep) => dep?._id === employeeData.departmentId)
        ?._id,
    });
    SetSelectedUnit({
      label: units.find((dep) => dep?._id === employeeData.unitId)?.name,
      value: units.find((dep) => dep?._id === employeeData.unitId)?._id,
    });
  }, [employeeData]);

  const updateNameValidationCheck = () => {
    const errors = {};
    const excludeHtml = /^[0-9a-zA-Z ,&,/-]+$/;
    if (!name.trim()) {
      errors.editName = "Employee Name is required";
    } else if (!excludeHtml.test(name)) {
      errors.editName = constatntMessages.invalidInput;
    }
    setErrors(errors);
  };

  const updateEmailValidationCheck = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      errors.editEmail = "Email is required";
    } else if (!emailRegex.test(email)) {
      errors.editEmail = "Invalid email format";
    }
    setErrors(errors);
  };

  const updateEmpIdValidationCheck = () => {
    const errors = {};
    const excludeHtml = /^[0-9a-zA-Z -.(),_/]+$/;
    if (!empId.trim()) {
      errors.editEmpId = "Employee ID is required";
    } else if (!excludeHtml.test(empId)) {
      errors.editEmpId = constatntMessages.invalidInput;
    }
    setErrors(errors);
  };
  const handleEditClick = (emp) => {
    setSelectedEmployee(emp);
    console.log("unit---", emp);

    setName(emp.name);

    setEmpId(emp.empId);
    setEmail(emp.email);
    setDesignationId(emp.designationId);
    setDepartmentId(emp.departmentId);
    setUnitId(emp.unitId);
    setShowEditModal(true);
    setIsMeetingOrganiser(emp.isMeetingOrganiser);
    setEditIsMeetingOrganiser(emp.isMeetingOrganiser);
    setIsAdmin(emp.setIsAdmin);
    setEditIsAdmin(emp.isAdmin);
    setSelectedOption([
      {
        label: emp?.designationDetails?.name,
        value: emp?.designationDetails?._id,
      },
    ]);
    setSelectedDepartmentOption([
      {
        label: emp?.departmentDetails?.name,
        value: emp?.departmentDetails?._id,
      },
    ]);
    setSelectedUnitOption([
      {
        label: emp?.unitDetails?.name,
        value: emp?.unitDetails?._id,
      },
    ]);
    setErrors(false);
  };
  console.log(designationId);

  const editIsValidate = () => {
    const errors = {};
    const excludeHtml = /^[0-9a-zA-Z -.(),-,_/]+$/;

    if (!name.trim()) {
      errors.editName = "Employee Name is required";
    } else if (!excludeHtml.test(name)) {
      errors.editName = constatntMessages.invalidInput;
    } else if (name.trim().length < 2 || name.trim().length > 100) {
      errors.editName = "Name must be  2 - 100  characters";
    }
    console.log(name.length);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      errors.editEmail = "Email is required";
    } else if (!emailRegex.test(email)) {
      errors.editEmail = "Invalid email format";
    }

    if (!empId.trim()) {
      errors.editEmpId = "Employee ID is required";
    } else if (!excludeHtml.test(empId)) {
      errors.editEmpId = constatntMessages.invalidInput;
    }

    setErrors(errors);
    return errors;
  };

  const handleEditSave = async (e) => {
    const newErrors = editIsValidate();
    e.preventDefault();
    setIsEditLoading(true);
    if (Object.keys(newErrors).length === 0) {
      try {
        const updateEmployee = {
          name,
          empId,
          email,
          departmentId,
          designationId,
          unitId,
          isAdmin: editIsAdmin,
          isMeetingOrganiser: editIsMeetingOrganiser,
        };
        console.log(updateEmployee);
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/V1/employee/editEmployee/${selectedEmployee?._id}`,
          updateEmployee,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: accessToken,
            },
          }
        );

        if (response.data.success) {
          setName("");
          setEmpId("");
          setEmail("");
          setDepartmentId("");
          setDesignationId("");
          setUnitId("");
          setEditIsMeetingOrganiser("");
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setSelectedEmployee(null);
          setShowEditModal(false);
          fetchEmployeeData();
          setShowEditModal(false);
          setErrors({ ...errors, editEmployeeData: "" });
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setShowEditModal(true);
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("Error while updating Employee:", error);
      } finally {
        setIsEditLoading(false);
      }
    } else {
      setIsEditLoading(false);
    }
  };

  const [employeeList, setEmployeeList] = useState(employees);

  console.log(employeeData.empId);
  const handleToggle = (empId, newStatus) => {
    console.log(empId);
    setEmployees((prevEmployees) =>
      prevEmployees.map((employee) =>
        employee?._id === empId
          ? { ...employee, isActive: newStatus }
          : employee
      )
    );
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  //View Single Employee Details
  const handleViewClick = async (employee) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/V1/employee/viewSingleEmployee/${employee?._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
        }
      );
      setViewEmployee(response.data.data);
      console.log(response.data.data);
      setShowViewModal(true);
    } catch (error) {
      console.error("Error fetching single employee details", error);
    }
  };

  console.log("Admin-", employeeData?.isAdmin);
  console.log("organizer-", viewEmployee?.isMeetingOrganiser);
  console.log("status", viewEmployee?.isActive);
  console.log(editIsAdmin);
  return (
    <div>
      {!accessToken ? <Navigate to="/login" /> : null}
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="Action-list-page">
          <div className="meeting-header-text">
            <h4>Add Employee</h4>
          </div>
          <div className="mt-2 table-box responsive-table">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="mb-1">
                      Employee Name<span className="star-mark"> *</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Employee"
                      name="name"
                      value={employeeData.name}
                      onChange={handleChange}
                      onBlur={nameValidationCheck}
                    />
                    {errors.name && (
                      <span className="error-message">{errors.name}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="mb-1">
                      Employee ID<span className="star-mark"> *</span>
                    </label>
                    <input
                      name="empId"
                      value={employeeData.empId}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter Employee ID"
                      onBlur={empIdValidationCheck}
                    />
                    {errors.empId && (
                      <span className="error-message">{errors.empId}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="mb-1">
                      Email<span className="star-mark"> *</span>
                    </label>
                    <input
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      value={employeeData.email}
                      onChange={handleChange}
                      onBlur={emailValidationCheck}
                    />{" "}
                    {errors.email && (
                      <span className="error-message">{errors.email}</span>
                    )}
                  </div>
                </div>
                {/* <div className="col-xl-4">
                  <div className="form-group">
                    <label className="mb-1">
                      Designation<span className="star-mark"> *</span>
                    </label>
                    <select
                      name="designationId"
                      value={employeeData.designationId}
                      onChange={handleChange}
                    >
                      <option value="">Select Designation</option>
                      {designations.map((designation) => (
                        <option key={designation?._id} value={designation?._id}>
                          {designation.name}
                        </option>
                      ))}
                    </select>
                    {errors.designationId && (
                      <span className="error-message">
                        {errors.designationId}
                      </span>
                    )}
                  </div>
                </div> */}
                <DesignationDropdown
                  designations={designations}
                  employeeData={employeeData}
                  handleChange={handleChange}
                  errors={errors}
                />
                {/* <div className="col-xl-4">
                  <div className="form-group">
                    <label className="mb-1">
                      Department<span className="star-mark"> *</span>
                    </label>
                    <select
                      name="departmentId"
                      value={employeeData.departmentId}
                      onChange={handleChange}
                    >
                      <option value="">Select Department</option>
                      {departments.map((department) => (
                        <option key={department?._id} value={department?._id}>
                          {department.name}
                        </option>
                      ))}
                    </select>
                    {errors.departmentId && (
                      <span className="error-message">
                        {errors.departmentId}
                      </span>
                    )}
                  </div>
                </div> */}
                <DepartmentDropdown
                  departments={departments}
                  employeeData={employeeData}
                  handleChange={handleChange}
                  errors={errors}
                />
                {/* <div className="col-xl-4">
                  <div className="form-group">
                    <label className="mb-1">
                      Unit<span className="star-mark"> *</span>
                    </label>
                    <select
                      name="unitId"
                      value={employeeData.unitId}
                      onChange={handleChange}
                    >
                      <option value="">Select Unit</option>
                      {units.map((unit) => (
                        <option key={unit?._id} value={unit?._id}>
                          {unit.name}
                        </option>
                      ))}
                    </select>
                    {errors.unitId && (
                      <span className="error-message">{errors.unitId}</span>
                    )}
                  </div>
                </div> */}
                <div className="col-md-4 col-sm-6">
                  {" "}
                  <UnitDropdown
                    units={units}
                    employeeData={employeeData}
                    handleChange={handleChange}
                    errors={errors}
                  />
                </div>
              </div>

              <div className="meeting-organiser d-flex align-items-center form-group">
                <input
                  type="checkbox"
                  name="isMeetingOrganiser"
                  checked={employeeData.isMeetingOrganiser}
                  onChange={handleChange}
                />{" "}
                <span>Set as Meeting Organizer </span>{" "}
                <span className="me-2">Or</span>
                <input
                  type="checkbox"
                  name="isAdmin"
                  checked={employeeData.isAdmin}
                  onChange={handleChange}
                />
                <span>Set as Admin</span>
              </div>
              {isLoading ? (
                <LoaderButton />
              ) : (
                <button className="save Mom-btn" disabled={isLoading}>
                  <p>Submit</p>
                </button>
              )}
            </form>
          </div>

          <div className="meeting-header-text">
            <h4>Manage Employee</h4>
          </div>
          <div className="mt-2 table-box responsive-table">
            <div className="tbl-text-search">
              <div className="left-tbl-text">
                {totalCount > 0 ? (
                  <p>
                    Showing {(page - 1) * limit + 1} to{" "}
                    {Math.min(page * limit, totalCount)} of {totalCount} entries
                  </p>
                ) : null}
              </div>
              <div className="search-box">
                <input
                  type="search"
                  autoComplete="off"
                  placeholder="Search By Employee"
                  value={searchKey}
                  onChange={handleSearch}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#4F2CC8"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </div>
            </div>

            {isFetching ? (
              <div className="tbl-text-search">
                <div className="meeting-page loader-cont">
                  <Loader />
                </div>
              </div>
            ) : employees?.length > 0 ? (
              <>
                <table className="mt-4 table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Employee Name</th>
                      <th>Employee ID</th>
                      {/* <th>Email</th>
                      <th>Designation</th>
                      <th>Department</th> */}
                      {/* <th>Unit</th> */}
                      <th>Updated At</th>
                      {/* <th>Is Meeting Organizer</th> */}
                      <th>Is Admin</th>
                      <th>Status</th>
                      <th className="action-col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employees.map((employee) => (
                      <tr key={employee?._id}>
                        <td data-label="Employee Name" className="word-break">
                          {employee.isMeetingOrganiser === true
                            ? employee.name + "(Organiser)"
                            : employee.name}
                        </td>
                        <td data-label="Employee ID">{employee.empId}</td>
                        {/* <td>{employee.email}</td> */}
                        {/* <td>{employee.designationDetails?.name}</td> */}
                        {/* <td>{employee.departmentDetails?.name}</td> */}
                        {/* <td>{employee.unitDetails?.name}</td> */}
                        <td
                          data-label="Updated At"
                          className="meeting-td-width-5"
                        >
                          {employee.formattedDate}
                          <p className="detail-date-time">
                            {employee.formattedTime}
                          </p>
                        </td>
                        {/* <td>{employee.isMeetingOrganiser ? "Yes" : "No"}</td> */}
                        <td
                          data-label="Is Admin"
                          className="meeting-td-width-5"
                        >
                          {employee.isAdmin ? "Yes" : "No"}
                        </td>
                        <td
                          data-label="Status"
                          className="meeting-td-width-5 metting-sts"
                        >
                          {/* <div> */}
                          <td>
                            <CustomizedSwitches
                              employee={employee}
                              onToggle={() => {
                                handleToggle(employee?._id);
                              }}
                            />
                          </td>
                          {/* </div> */}
                        </td>
                        {/* <td>{employee.isActive ? "Active" : "Deactive"}</td> */}
                        <td
                          data-label="Action"
                          className="text-align-center meeting-td-width-7"
                        >
                          <Dropdown>
                            {/* <div className="d-inline-block menu-dropdown custom-dropdown"> */}
                            <div className="dropdown-opt d-inline-block menu-dropdown custom-dropdown">
                              <Dropdown.Toggle id="dropdown-basic">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#000"
                                  className="bi bi-three-dots-vertical"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                </svg>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => handleViewClick(employee)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17"
                                    height="17"
                                    fill="currentColor"
                                    className="bi bi-eye me-2"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                  </svg>
                                  View
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleEditClick(employee)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="me-2 bi bi-pencil-square"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path
                                      fillRule="evenodd"
                                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                    />
                                  </svg>
                                  Edit
                                </Dropdown.Item>
                                {/* <Dropdown.Item
                                  onClick={() => handleDeleteClick(employee)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="me-2 bi bi-trash3"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                  </svg>
                                  Delete
                                </Dropdown.Item> */}
                              </Dropdown.Menu>
                            </div>
                            {/* </div> */}
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="tbl-bottom">
                  <div className="left-tbl-bottom">
                    {/* Left Arrow Button */}
                    {page !== 1 ? (
                      <button
                        className="left-arrow"
                        onClick={() => setPage(page > 1 ? page - 1 : 1)}
                        disabled={page === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#fff"
                          className="bi bi-chevron-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                          />
                        </svg>
                      </button>
                    ) : null}

                    {/* Pagination Component from MUI without arrows */}
                    <Stack spacing={2}>
                      <Pagination
                        count={totalPage}
                        page={page}
                        siblingCount={0}
                        boundaryCount={2}
                        onChange={handlePageChange}
                        hidePrevButton
                        hideNextButton
                      />
                    </Stack>

                    {/* Right Arrow Button */}
                    {page < totalPage ? (
                      <button
                        className="right-arrow"
                        onClick={() =>
                          setPage(page * limit < totalCount ? page + 1 : page)
                        }
                        disabled={page * limit >= totalCount}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#fff"
                          className="bi bi-chevron-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                          />
                        </svg>
                      </button>
                    ) : null}
                  </div>

                  {/* Rows Per Page Selector */}
                  <div className="right-tbl-bottom">
                    <p>Rows Per Page</p>
                    <select
                      className="no-opt-box"
                      name="limit"
                      onChange={handleRowsPerPageChange}
                      value={limit}
                    >
                      {Array.from({ length: maxOption / 5 }).map((_, index) => {
                        const value = (index + 1) * 5;
                        return (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <div className="no-data-img">
                <>
                  <Alert
                    status={"info"}
                    message={"No data available."}
                    timeoutSeconds={0}
                  />
                  <NoDataFound dataType={"meeting"} />
                  <div className="button-outer">
                    <Button variant="primary" onClick={handleClearSearch}>
                      Clear
                    </Button>
                  </div>
                </>
              </div>
            )}
          </div>
          <Modal show={showViewModal} onHide={() => setShowViewModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>View Employee Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <table className="table table-bordered modal-table">
                <tbody>
                  <tr>
                    <th>Employee Name</th>
                    <td>{viewEmployee?.name}</td>
                  </tr>
                  <tr>
                    <th>Employee ID</th>
                    <td>{viewEmployee?.empId}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{viewEmployee?.email}</td>
                  </tr>
                  <tr>
                    <th>Designation</th>
                    <td>{viewEmployee?.designationDetails?.name}</td>
                  </tr>
                  <tr>
                    <th>Department</th>
                    <td>{viewEmployee?.departmentDetails?.name}</td>
                  </tr>
                  <tr>
                    <th>Unit</th>
                    <td>{viewEmployee?.unitDetails?.name}</td>
                  </tr>
                  <tr>
                    <th>Is Meeting Organizer</th>
                    <td>{viewEmployee?.isMeetingOrganiser ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <th>Is Admin</th>
                    <td>{viewEmployee?.isAdmin ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>{viewEmployee?.isActive ? "Active" : "Deactive"}</td>
                  </tr>
                </tbody>
              </table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" onClick={() => setShowViewModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Employee</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form class="employee-form">
                <Form.Group controlId="designationName" className="form-group">
                  <Form.Label className="mb-1">Employee Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Employee Name"
                    value={name}
                    autoComplete="off"
                    onBlur={updateNameValidationCheck}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
                {errors?.editName && (
                  <span className="error-message">{errors?.editName}</span>
                )}

                <Form.Group controlId="employeeId" className="form-group">
                  <Form.Label className="mb-1">Employee ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Employee ID"
                    value={empId}
                    autoComplete="off"
                    onBlur={updateEmpIdValidationCheck}
                    onChange={(e) => setEmpId(e.target.value)}
                  />
                </Form.Group>
                {errors?.editEmpId && (
                  <span className="error-message">{errors?.editEmpId}</span>
                )}

                <Form.Group controlId="email" className="form-group">
                  <Form.Label className="mb-1">Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Email"
                    value={email}
                    autoComplete="off"
                    onBlur={updateEmailValidationCheck}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                {errors?.editEmail && (
                  <span className="error-message">{errors?.editEmail}</span>
                )}
                {/* <div className="form-group">
                  <label className="mb-1">
                    Designation<span className="star-mark"> *</span>
                  </label>
                  <select
                    name="designationId"
                    value={designationId}
                    onChange={(e) => setDesignationId(e.target.value)}
                  >
                    <option value="">Select Designation</option>
                    {designations.map((designation) => (
                      <option key={designation?._id} value={designation?._id}>
                        {designation?.name}
                      </option>
                    ))}
                  </select>
                  {errors?.designationId && (
                    <span className="error-message">
                      {errors?.designationId}
                    </span>
                  )}
                </div> */}
                <EditDesignationDropdown
                  designations={designations}
                  selectedDesignationId={searchData.designation}
                  handleChange={(e) => {
                    handleDesignationChange(e);
                  }}
                  value={selectedOption}
                />
                {/* <div className="form-group">
                  <label className="mb-1">
                    Department<span className="star-mark"> *</span>
                  </label>
                  <select
                    name="departmentId"
                    value={departmentId}
                    onChange={(e) => setDepartmentId(e.target.value)}
                  >
                    <option value="">Select Department</option>
                    {departments.map((department) => (
                      <option key={department?._id} value={department?._id}>
                        {department?.name}
                      </option>
                    ))}
                  </select>
                  {errors?.departmentId && (
                    <span className="error-message">
                      {errors?.departmentId}
                    </span>
                  )}
                </div> */}

                <EditDepartmentDropdown
                  departments={departments}
                  selectedDepartmentId={searchData.department}
                  handleChange={(e) => {
                    handleDepartmentChange(e);
                  }}
                  value={selectedDepartmentOption}
                />
                <EditUnitDropdown
                  units={units}
                  selectedUnitId={searchData.unit}
                  handleChange={(e) => {
                    handleUnitChange(e);
                  }}
                  value={selectedUnitOption}
                  errors={errors}
                />
                {/* <div className="form-group">
                  <label className="mb-1">
                    Unit<span className="star-mark"> *</span>
                  </label>
                  <select
                    name="unitId"
                    value={unitId}
                    onChange={(e) => setUnitId(e.target.value)}
                  >
                    <option value="">Select Unit</option>
                    {units.map((unit) => (
                      <option key={unit?._id} value={unit?._id}>
                        {unit?.name}
                      </option>
                    ))}
                  </select>
                  {errors?.unitId && (
                    <span className="error-message">{errors?.unitId}</span>
                  )}
                </div> */}
                <div className="meeting-organiser d-flex align-items-center">
                  <input
                    type="checkbox"
                    name="editIsMeetingOrganiser"
                    value={isMeetingOrganiser}
                    checked={editIsMeetingOrganiser ? true : false}
                    onChange={editHandleChange}
                  />{" "}
                  <span>Set as Meeting Organizer </span>{" "}
                  <span className="me-2">Or</span>
                  <input
                    type="checkbox"
                    name="editIsAdmin"
                    value={isAdmin}
                    checked={editIsAdmin ? true : false}
                    onChange={editHandleChange}
                  />
                  <span>Set as Admin</span>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button
                variant="light"
                className="btn-light"
                onClick={() => setShowEditModal(false)}
              >
                Close
              </Button> */}
              {/* <Button variant="primary" onClick={handleEditSave}>
                Save Changes
              </Button> */}

              {isEditLoading ? (
                <LoaderButton />
              ) : (
                <Button variant="primary" onClick={handleEditSave}>
                  Save Changes
                </Button>
              )}
            </Modal.Footer>
          </Modal>
          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this Department?
            </Modal.Body>
            <Modal.Footer>
              {/* <Button
                variant="light"
                onClick={() => setShowDeleteModal(false)}
                className="btn-light"
              >
                <p>Cancel</p>
              </Button> */}
              <Button variant="primary" onClick={handleDeleteConfirm}>
                <p>Delete</p>
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Employee;

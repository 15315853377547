import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import axios from "../../../../node_modules/axios/index";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 40, // Increase width for a larger switch
  height: 24, // Increase height for a larger switch
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 20, // Larger thumb width
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(16px)", // Adjust for larger thumb size
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(16px)", // Adjust translation for larger thumb
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 24, // Larger thumb
    height: 24, // Larger thumb height
    borderRadius: 12, // Make the thumb more circular
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 50 / 2, // Increase track border radius
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const CustomizedSwitches = ({ unit, onToggle }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?._id;
  const accessToken = localStorage.getItem("accessToken");
  const organizationId = userData?.organizationId;
  const [empId, setEmpId] = useState("");
  const [isActive, setIsActive] = useState(unit.isActive);
  console.log(isActive);
  useEffect(() => {
    if (unit) {
      setIsActive(unit.isActive);
    }
  }, [unit]);

  const handleToggle = async (unitId, isactive) => {
    // const newStatus = event.target.checked;
    // console.log(newStatus);
    const updateUnit = { isActive: isactive, organizationId };
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/V1/unit/editUnit/${unitId}`,
      updateUnit,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      }
    );
    setIsActive(!isActive);
    if (response.data.success) {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{isActive ? "Active" : "Deactive"}</Typography>
        {/* <AntSwitch
          checked={isActive}
          onClick={() => {
            handleToggle(employee._id, !isActive);
          }}
          inputProps={{ "aria-label": "ant design" }}
        /> */}

        <Switch
          checked={isActive}
          onClick={() => {
            handleToggle(unit._id, !isActive);
          }}
          inputProps={{ "aria-label": "ant design" }}
        />
      </Stack>
    </FormGroup>
    // <FormGroup>
    //   <Form.Check // prettier-ignore
    //     type="switch"
    //     id="custom-switch"
    //     label="Check this switch"
    //     checked={isActive}
    //     onClick={() => {
    //       handleToggle(employee._id, !isActive);
    //     }}
    //   />
    // </FormGroup>
  );
};

export default CustomizedSwitches;
